import { z } from 'zod';
import { TemplateStatus } from '../../SimpleMessages/template-status.enum';
import { TemplateType } from '../../SimpleMessages/template-type.enum';

export const templateV2Schema = z.object({
	id: z.string(),
	title: z.string().default(''),
	content: z.string().default(''),
	status: z.nativeEnum(TemplateStatus).default(TemplateStatus.NEW),
	type: z.nativeEnum(TemplateType).default(TemplateType.TENANT),
	shop_types: z.array(z.string()).default([]),
	compatibilities: z.array(z.string()).default([]),
	order: z.number().default(0),
	categories: z.array(z.string()).default([]),
	filter_criteria: z.array(z.string()).default([]),
	disabled: z.boolean().default(false),
	createdAsCustomMessage: z.boolean().optional(),
});

export type TemplateV2 = z.infer<typeof templateV2Schema>;
